<template>
  <div
    class="sender-content"
    :style="`height: ${isFile ? 44 : 78 + $store.state.messageHeight + 10}px`"
    v-if="infos"
  >
    <div class="sender-thumbnail">
      <img :src="infos.created_by.avatar" alt="" class="" />
    </div>
    <div class="infos">
      <div class="sender-name">
        {{ infos.created_by.first_name }}
        {{ infos.created_by.last_name }}
      </div>
      <div class="expiration">
        <v-icon small left>mdi-timer-sand-full</v-icon>
        {{ infos.expire_at }}
      </div>
    </div>
    <div class="sender-subject" v-if="infos.subject">{{ infos.subject }}</div>
    <div
      class="sender-message"
      v-if="infos.message"
      v-html="infos.message"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    infos: Object,
    isFile: {
      default: false,
    },
  },
  watch: {
    infos: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            const messageHeight =
              document.querySelector(".sender-message")?.offsetHeight || 0;
            this.$store.commit("SET_MESSAGE_HEIGHT", messageHeight);
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style>
.sender-thumbnail {
  grid-area: sender-thumbnail;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  transition: 200ms;
}

.sender-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.infos {
  grid-area: infos;
  display: flex;
  justify-content: space-between;
}
.sender-name {
  font-weight: 500;
  color: rgb(65, 65, 65);
}
.is_file .sender-content {
  height: 44px;
  grid-template-columns: 36px 1fr;
}
.sender-content {
  margin-left: 10px;
  height: 68px;
  background-color: white;
  padding: 10px;
  font-size: 13px;
  border-radius: 8px;
  max-width: 400px;
  min-width: 300px;
  overflow: hidden;
  transition: height 200ms;
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  grid-template-areas: "sender-thumbnail infos" "sender-subject sender-subject" "sender-message sender-message";
  box-shadow: 0px 2px 6px 0px #9393934f;
}
.login-screen .sender-content {
  height: 68px !important;
}
.sender-subject {
  grid-area: sender-subject;
  line-height: 1.5em;
  color: rgb(54, 47, 47);
  font-weight: bold;
  margin-top: 5px;
  text-align: center;
}
.sender-message {
  grid-area: sender-message;
  line-height: 1.5em;
  color: rgb(116, 116, 116);
  margin-top: 5px;
}
.body.is_file .sender-content {
  min-height: 44px !important;
}
.body.is_file .header-inner {
  row-gap: 8px;
  margin: 0px 10px;
}
/* .body.is_file .sender-thumbnail,
.login-screen .sender-thumbnail {
  width: 25px;
  height: 25px;
} */
.expiration-title {
  margin-left: 4px;
}

@media screen and (max-width: 1024px) {
  .is_file .sender-content {
    height: 48px;
  }
  .sender-thumbnail {
    width: 25px;
    height: 25px;
  }
  .sender-content {
    margin: 0px;
    border-radius: 8px;
    grid-template-columns: 36px 1fr;
  }
  .expiration {
    font-size: 12px;

  }
}
</style>