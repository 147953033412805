<template>
  <div class="icon-type">
    <svg
      width="90"
      height="120"
      viewBox="0 0 90 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 120H86C88.2091 120 90 118.209 90 116V30L60 0H4C1.79086 0 0 1.79086 0 4V116C0 118.209 1.79086 120 4 120Z"
        class="background"
      />
      <path d="M85 31L59 5V31H85Z" fill="white" />
    </svg>
    <span :style="style" class="icon-type-title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    color: String,
  },
  computed: {
    style() {
      return {
        fontSize: `${(12 / this.title.length) * 0.5}em`,
      };
    },
  },
};
</script>
<style scoped>
.icon-type {
  position: relative;
  width: 90px;
  height: 120px;
}

span.icon-type-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  font-family: Arial;
  font-size: 20px;
}

.background {
  fill: var(--background);
}
</style>