<template>
  <v-app>
  <div
    class="body"
    :class="tree != null && isFile ? 'is_file' : ''"
  >
    <Header :isFile="isFile" :preview="$store.state.share" />
    <main v-if="!tree || !object">
      <div class="browser-container flex">
        <div class="grid">
          <div class="grid-inner">
            <v-skeleton-loader :key="i" v-for="i in 15" boilerplate type="card"></v-skeleton-loader>
          </div>
        </div>
      </div>
    </main>
    <transition v-if="tree && object" name="fade" mode="out-in">
      <main :key="$route.path">
        <nav class="menu">
          <div class="flex">
            <v-breadcrumbs :items="breadcrumbLinks">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :key="item.title"
                  :disabled="item.disabled"
                  active-class="active"
                  tag="router-link"
                  :to="{
                    params: {
                      hash: $route.params.hash,
                      install: $route.params.install,
                      path: item.path,
                    },
                  }"
                >
                <v-icon v-if="item.first" left>mdi-home</v-icon>
                {{ folderByTitle[item.title] }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </div>
          <v-btn
            type=""
            :style="{ background: tree.configuration.color, color: 'white' }"
            v-if="
              object.assets &&
              object.assets.length > 0 &&
              object.assets[0].filename != null
            "
            class="btn"
            id="download"
            @click="handleClickDownloadAllFiles(object)"
            ><span class="download-title">Download</span>({{
              object.assets.length
            }})<v-icon right dark>mdi-download</v-icon></v-btn>
          <v-btn
            :style="{ background: tree.configuration.color, color: 'white' }"
            v-if="
              object.filename != null &&
              isProgram(object.type) == false &&
              isVideo(object.type, object.extension) == false
            "
            :class="['btn', 'download-button-with-title']"
            id="download"
            @click="downloadFile(object)"
            >Download <v-icon right dark>mdi-download</v-icon></v-btn>
        </nav>
        <div
          class="browser-container flex"
          :class="[isProgram(object.type) ? 'is-program' : '']"
          v-if="errorCode == null"
        >
          <div class="grid">
            <div class="grid-inner" v-if="$store.state.share.assets.length > 0">
              <Card
                v-for="(asset, index) in object.assets"
                :key="object.slug + '-' + index"
                :asset="asset"
                :asset_index="index"
                :hash="$route.params.hash"
                :install="$route.params.install"
              />
            </div>
            <div class="grid-inner" v-else>
              <CardSkeleton v-for="index in 30" :key="index" />
            </div>
            <CsvViewer v-if="isFile && object.extension == 'csv'" :file="object.url" />
            <div class="preview" v-if="isFile">
              <div class="is-animated">
                <iframe
                  v-if="['docx', 'xls'].includes(object.extension)"
                  :src="`https://view.officeapps.live.com/op/embed.aspx?src=${object.url}`"
                  frameborder="0"
                >
                  This is an embedded
                  <a target="_blank" href="http://office.com"
                    >Microsoft Office</a
                  >
                  document, powered by
                  <a target="_blank" href="http://office.com/webapps"
                    >Office Online</a
                  >.
                </iframe>

                <video
                  v-if="isVideo(object.type, object.extension)"
                  controls
                  controlslist="nodownload"
                  autoplay
                  alt=""
                >
                  <source :src="object.url" :type="object.mime" />
                </video>
                <img
                  @error="handleFileError"
                  v-if="isImage(object.type, object.extension)"
                  :src="object.url"
                  alt=""
                />
              </div>
            </div>
          </div>
          <aside v-if="isProgram(object.type)">
            <div class="aside-inner">
              <video
                :poster="object.thumb"
                controls="controls"
                controlslist="nodownload"
                v-if="object.infos.screener"
                :src="object.infos.screener"
              ></video>
              <h1>{{ object.name }}</h1>
              <p>{{ object.description }}</p>
            </div>
          </aside>
        </div>
        <div v-else class="error-message-container">
          <div class="title">
            <h1>Page not found. {{ errorCode }} </h1>
          </div>
        </div>
      </main>
    </transition>
  </div>
</v-app>
</template>
<script>
import mime from "mime";
import prettyBytes from "pretty-bytes";
import Cookies from "js-cookie";
import axios from "axios";
import { parseISO, formatDistanceToNow, format } from "date-fns";
import { fr } from "date-fns/locale";
import { saveAs } from "file-saver";

import mixin from "@/mixin.js";

import { EXPIRED, PATH_NOT_EXIST } from "@/utils";

import CardSkeleton from "@/components/CardSkeleton.vue";
import CsvViewer from "@/components/CsvViewer.vue";
import Card from "@/components/Card.vue";
import Header from "./Header.vue";

export default {
  mixins: [mixin],
  components: { Header, Card, CardSkeleton, CsvViewer },
  data() {
    return {
      isLoginModalActive: true,
      fieldLogin: null,
      tree: null,
      breadcrumb: [],
      object: null,
      folderByTitle: {},
      isRoute404: false,
    };
  },
  mounted() {
    document.addEventListener("contextmenu", function (e) {
      if (e.target.nodeName === "IMG") {
        e.preventDefault();
      }
    });

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  },
  computed: {
    share() {
      return this.$store.state.share;
    },
    errorCode() {
      return this.$store.state.errorCode;
    },
    isFile: function () {
      return !this?.object?.assets;
    },
    breadcrumbLinks: function () {
      const breadcrumb = [];
      let path = [];
      for (let index = 0; index < this.breadcrumb.length; index++) {
        const crumb = this.breadcrumb[index]
        path = [...path, crumb];
        breadcrumb.push({
          path,
          title: crumb,
          disabled: index === this.breadcrumb.length -1
        });
      }

      breadcrumb[0].first = true

      return breadcrumb;
    },
  },
  methods: {
    init() {
      this.tree = this.$store.state.share;
      this.tree.type = "home";
      this.tree.slug = "home";
      this.tree.name = "home";
      if (!this.tree.expire_at) {
        const expire_at = parseISO(this.tree.expire_at);
        const relative_espire_at = formatDistanceToNow(expire_at, {
          locale: fr,
          addSuffix: true,
        });
        this.tree.expire_at = format(expire_at, "dd/MM/yyyy");
        this.tree.relative_espire_at = relative_espire_at;
      }
      this.reccursiveInit(this.tree);
      this.onBreacrumbUpdate(this.breadcrumb);
      document.documentElement.style.setProperty(
        "--background",
        this.tree.configuration.color
      );
    },
    handleClickDownloadAllFiles(object) {
      if (window.confirm(`Le téléchargement sous forme d'archive est actuellement indisponible, les assets seront téléchargés les uns après les autres. \n\n Cela représente entre 3 à 5 fichiers par programme. \n\n Voulez-vous continuer ?`)) {
        this.downloadAllFiles(object)
      }
    },
    downloadAllFiles(object) {
        object.assets.forEach(e => {

          if(this.isProgram(e.type)) {
            this.downloadAllFiles(e)
          } else if (
            e.url &&
            e.filename &&
            this.isVideo(e.type, e.extension) == false
          ) {
            fetch(e.url)
              .then((res) => res.blob())
              .then((blob) => {
                saveAs(blob, e.filename + (e.type == "" ? "" : ("." + e.extension)));
              });
          }
        });
    },
    downloadFile(object) {
      fetch(object.url)
        .then((res) => res.blob())
        .then((blob) => {
          saveAs(blob, object.filename + (object.type == "" ? "" : ("." + object.extension)));
        });
    },
    handleFileError: function () {
      const url = `https://${this.$route.params.install}.secom.io/data/shares/${this.$route.params.hash}`;
      axios
        .get(url)
        .then()
        .catch((err) => {
          this.infos = err.response.data;
          Cookies.remove("pass");
          this.$store.dispatch("setErrorCode", err.response.status);
          if (err.response.status == EXPIRED) {
            this.emmitNotificationError(
              this.$store.getters.errorMessageLogin,
              () => {
                this.$router.push({
                  name: "Login",
                  params: {
                    install: this.$route.params.install,
                    hash: this.$route.params.hash,
                  },
                });
              }
            );
          }
        });
    },
    handleContextMenu: function (e) {
      e.preventDefault();
    },
    reccursiveInit(object, parent) {
      if (!parent) {
        object.name = "Home";
      }
      object.slug = slugify(object.name);
      this.folderByTitle[object.slug] = object.name;
      object.path = [];
      if (parent && parent.path != "") {
        object.path = [...parent.path];
      }
      object.path = [...object.path, object.slug];
      if (object.assets) {
        for (let asset of object.assets) {
          if (asset.filesize) {
            asset.hsize = prettyBytes(Number(asset.filesize), { locale: "fr" });
          }
          if (asset.mime) {
            if (["logo", "screenshot", "default"].includes(asset.mime)) {
              asset.type = asset.mime;
            } else {
              if (asset.mime == "csv") {
                asset.mime = "text/csv";
              }
              if (asset.mime == "application/msexcel") {
                asset.mime = "application/vnd.ms-excel";
              }
              //asset.type = mime.extension(asset.mime);
            }
            asset.extension = mime.getExtension(asset.mime);
          }
          if (asset.type) {
            if (["docx", "xls"].includes(asset.type)) {
              asset.medias = [
                {
                  component: this["vue-doc"],
                  props: { value: asset.url, type: "office" },
                },
              ];
            } else if (asset.url) {
              asset.medias = [asset.url];
            }

            if (this.isProgram(asset.type)) {
              asset.infos = {
                screener: asset.assets.find((el) => {
                  return el.name == "Screener";
                })?.url,
              };
            }
          }
          this.reccursiveInit(asset, object);
        }
      }
    },
    onBreacrumbUpdate(breadcrumb) {
      // Par défaut, current est à la racine
      if(this.tree && this.tree.assets != 0) {
        let current = { assets: [this.tree], type: "root" };
        // Si on trouve dans un des niveau du fil d'ariane, un slug correspond au slug présent dans la liste des assets,
        breadcrumb.forEach((level) => {
          current = current.assets.find((asset) => {
            return asset.slug == level
          });
        });
        if (current) {
          this.object = current;
          document.title = current.name
            ? "SECOM Share | " + (current.name == "home" ? "Home" : current.name)
            : "";
        } else {
          this.$store.dispatch("setErrorCode", PATH_NOT_EXIST);
        }
      }

    },
  },
  watch: {
    share: {
      immediate: true,
      handler() {
        this.init();
      },
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = "SECOM | Share" + " - " + to.meta.title;
        let path = null;
        if (to.params.path) {
          path =
            typeof to.params.path == "string"
              ? to.params.path.split("/")
              : to.params.path;
        } else {
          path = [];
        }
        this.breadcrumb = path;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      },
    },
    breadcrumb: {
      immediate: true,
      handler(breadcrumb) {
        this.onBreacrumbUpdate(breadcrumb);
      }
    },
    errorCode(errorCode) {
      if(errorCode == PATH_NOT_EXIST){
        this.emmitNotificationError("Page Not Found", () => {
          this.$store.dispatch("setErrorCode", null);
          this.$router.push({
            name: "Browse",
            params: {
              install: this.$route.params.install,
              hash: this.$route.params.hash,
              path: "home",
            },
          });
        });
      }
    }
  },
};
function slugify(text) {
  text = text || "";
  return text
    .toString()
    .toLowerCase()
    .replace(/(\w)'/g, "$1") // Special case for apostrophes
    .replace(/[^a-z0-9_-]+/g, "-") // Replace all non-word chars with -
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
* {
  font-family: Roboto;
}
body {
  width: 100%;
  margin: 0;
  min-height: 100vh;
  background: rgb(250, 250, 250);
}
main {
  width: 1070px;
  margin: 0 auto;
  padding-top: 20px;
}

img {
  user-select: none;
  pointer-events: none;
}

video {
  width: 100%;
}


.skeleton_list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
}

.breadcrumb {
  display: flex;
  align-items: center;
  font-family: Futura;
}

.breadcrumb .crumb {
  margin-right: 5px;
  align-items: center;
  display: flex;
}

.breadcrumb .crumb a {
  color: grey;
  text-decoration: none;
}

.crumb div {
  padding: 4px 6px;
}

.crumb:last-child a {
  color: black;
}

.breadcrumb .crumb:not(:last-child):after {
  content: ">";
  display: block;
  margin-left: 5px;
}

.grid {
  width: 100%;
}
.browser-container aside {
  width: 400px;
  min-height: 700px;
  flex-shrink: 0;
}

.browser-container .aside-inner video {
  padding: 0 10px;
}
.browser-container .aside-inner h1 {
  padding: 0 10px;
  font-size: 19px;
  font-weight: 600;
}
.browser-container .aside-inner p {
  padding: 0 10px;
}

* {
  box-sizing: border-box;
}
aside video {
  width: 100%;
}

/* .btn {
  padding: 10px 20px;
  background-color: rgb(240, 240, 240);
  border: none;
  text-transform: capitalize;
  border-radius: 4px;
  cursor: pointer;
} */

@media screen and (max-width: 500px) {
  .menu {
    flex-wrap: wrap;
  }
  .breadcrumb {
    flex-wrap: wrap;
  }
  /*   .breadcrumb .crumb:not(:last-child):after {
    display: none;
  } */
}

.btn:hover {
  background: rgb(230, 230, 230);
}

.grid-inner {
  display: grid;
  grid-template-columns: repeat(5, 200px);
  gap: 20px;
  margin: 10px;
}
.is-program .grid-inner {
  grid-template-columns: repeat(3, 200px);
}

.preview {
  max-width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview iframe {
  width: 100%;
  height: 100vh;
}

.preview :is(video, img) {
  max-width: 100%;
}

.route-404 {
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.route-404 h1 {
  font-size: 5em;
  font-weight: 600;
}

.asset-type {
  text-transform: uppercase;
  font-weight: 600;
}
.root.fslightbox-source {
  width: 100% !important;
}
.body {
  transition: 200ms;
}

.menu {
  display: flex;
  justify-content: space-between;
  margin: 10px 10px 20px;
}

.error-message-container {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .browser-container aside {
    min-height: unset;
  }
  .grid-inner {
    grid-template-columns: repeat(4, 200px);
  }
  .is-program .grid-inner {
    grid-template-columns: repeat(2, 200px);
  }
  main {
    width: 100%;
    margin: 0 auto;
  }

  .is-program .grid-inner {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .browser-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .browser-container aside {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .grid-inner {
    grid-template-columns: repeat(2, minmax(160px, 1fr));
  }
}
@media screen and (min-width: 1024px) {
  .browser-container {
    min-height: 70vh;
  }
}
@media screen and (min-width: 380px) {
  .download-button-with-title {
    display: block !important;
  }
  .download-button-without-title {
    display: none !important;
  }
}
@media screen and (max-width: 380px) {
  .grid-inner {
    grid-template-columns: repeat(2, minmax(160px, 1fr));
  }
  .download-title {
    display: none;
  }
  .download-button-with-title {
    display: none !important;
  }
  .download-button-without-title {
    display: block !important;
  }
}
</style>
