<template>
  <v-app>
    <div
      class="login-screen"
      v-if="installInfos"
    >
      <div class="h-full columns is-3">
        <img
          class="h-full column is-8 cover"
          :src="installInfos.visuel"
        />
        <div
          class="flex items-center column is-vcentered is-flex-direction-column"
        >
          <img
            class="logo-secom"
            :src="installInfos.logo"
          />
          <div style="height: 68px">
            <SenderCard
              :infos="infos"
              :isFile="true"
            />
          </div>
          <form
            @submit.prevent="handleSubmitLogin"
            class="login-form"
          >
            <v-text-field
              label="Enter your email to access"
              placeholder="Email"
              v-model="fieldEmail"
              type="email"
              :disabled="
                $store.state.isLoadingShare || Boolean(errorMessageLogin)
              "
              required
              outlined
              hide-details="auto"
            ></v-text-field>
            <div class="field">
              <div class="control">
                <v-checkbox
                  v-model="fieldIsAgreeTerms"
                  :disabled="
                    $store.state.isLoadingShare || Boolean(errorMessageLogin)
                  "
                >
                  <template v-slot:label>
                    <div>
                      I accept the
                      <a
                        href="/terms-and-conditions.html"
                        @click.stop
                        target="_blank"
                      >
                        terms and conditions
                      </a>
                    </div>
                  </template>
                </v-checkbox>
              </div>
            </div>
            <v-btn
              color="primary"
              native-type="submit"
              :disabled="fieldIsAgreeTerms == false"
              :loading="$store.state.isLoadingShare"
              @click="handleSubmitLogin"
              >Access</v-btn
            >
            <v-alert
              v-if="isError"
              dense
              outlined
              prominent
              type="error"
              >{{ errorMessageLogin }}</v-alert
            >
          </form>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";

import { checkValidEmail } from "@/utils.js";
import mixin from "@/mixin";

import SenderCard from "@/components/SenderCard.vue";

export default {
  mixins: [mixin],
  components: { SenderCard },
  data() {
    return {
      infos: null,
      fieldEmail: null,
      fieldIsAgreeTerms: false,
    };
  },
  methods: {
    handleSubmitLogin: function () {
      this.$store.state.isLoadingShare = true;
      const pass = checkValidEmail(this.fieldEmail) ? this.fieldEmail : false;
      if (pass) {
        const { hash, install } = this.$route.params;
        Cookies.set("pass", pass);
        this.$store.commit("SET_IS_LOADING_SHARE", true);
        this.$router.push({
          name: "Browse",
          params: { install, hash, path: "home" },
        });
      }
    },
  },
  mounted() {
    const url = `https://${this.$route.params.install}.secom.io/data/shares/${this.$route.params.hash}`;
    axios
      .get(url)
      .then()
      .catch((err) => {
        this.infos = err.response.data;
        this.$store.dispatch("setErrorCode", err.response.status);
      });
  },
};
</script>

<style>
.flex {
  display: flex !important;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.login-screen .cover {
  height: 100vw;
  background: grey;
}

.login-screen .cover.column {
  padding: 0;
}

.login-screen .logo-secom {
  object-fit: contain;
}

@media screen and (max-width: 480px) {
  .login-screen .logo-secom {
    max-width: 100px;
    height: 100px;
  }
}

@media screen and (min-width: 480px) {
  .login-screen {
    height: 70vh;
  }
  .login-screen .cover {
    height: 70vh;
  }
  .login-screen .logo-secom {
    max-width: 200px;
    height: 200px;
  }
}

.login-screen form {
  margin: 20px 20px 0;
}

.login-form .field {
  width: 400px;
}

.login-screen form .notification {
  margin: 10px 0;
}

.cover {
  object-fit: cover;
}

.h-full {
  height: 100%;
}
</style>
