<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div class="text-truncate" style="width: 130px!important" :key="property">
        <span @click="copyContent(item[property])" v-bind="attrs" v-on="on" style="text-decoration: underline dotted;">
          {{ item[property] }}
        </span>
      </div>
    </template>
    <div class="tooltip-content">
     {{ item[property] }}
    </div>
    <v-snackbar v-model="copied" ><v-icon left>mdi-content-copy</v-icon> Copied !</v-snackbar>
  </v-tooltip>
</template>
<script>
  export default {
    props: ['property', 'item'],
    data() {
      return {
        copied: false
      }
    },
    methods: {
      async copyContent(content) {
        console.log('hello', content)
        await navigator.clipboard.writeText(content);
        this.copied = true
        setTimeout(() => {
          this.copied = false
        }, 5000)
      }
    }
  }
</script>
<style scoped>
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: fit-content;
}
.v-tooltip {
  display: block !important;
}
</style>
<style>
.text-start {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: fit-content;
}
.tooltip-content {
  width: 250px !important;
  overflow-wrap: break-word;
  white-space: normal;
}
</style>