import Vue from 'vue'
import Vuex from 'vuex'
import { messageFromErrorCode, EXPIRED, NOT_FOUND, PATH_NOT_EXIST } from '@/utils.js'
import Cookies from "js-cookie";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    share: null,
    isLoadingShare: false,
    errorCode: null,
    messageHeight: 0,
  },
  getters: {
    isError(state) {
      return Boolean(state.errorCode)
    },
    errorMessageLogin(state) {
      return messageFromErrorCode[state.errorCode]
    }
  },
  mutations: {
    SET_ERROR_CODE(state, errorCode) {
      state.errorCode = errorCode
    },
    SET_SHARE(state, share) {
      state.share = share
    },
    SET_IS_LOADING_SHARE(state, isLoadingShare) {
      state.isLoadingShare = isLoadingShare
    },
    SET_ERROR_MESSAGE_LOGIN(state, errorMessageLogin) {
      state.errorMessageLogin = errorMessageLogin
    },
    SET_MESSAGE_HEIGHT(state, messageHeight) {
      state.messageHeight = messageHeight
    }
  },
  actions: {
    setErrorCode({ commit }, errorCode) {
      if(null === errorCode) {
        commit('SET_ERROR_CODE', null)
      }
      if (EXPIRED == errorCode) {
        commit('SET_ERROR_CODE', errorCode)
        Cookies.remove("pass");
      }
      if ([EXPIRED, NOT_FOUND].includes(errorCode)) {
        Cookies.remove("pass");
      }
      if ([EXPIRED, NOT_FOUND, PATH_NOT_EXIST].includes(errorCode)) {
        commit('SET_ERROR_CODE', errorCode)
      }
    }
  }
})
export default store