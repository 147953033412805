<template>
  <router-link
    :to="{
      params: { hash, install, path: asset.path },
      query: { pass: $route.query.pass },
    }"
  >
    <div class="item" :class="`item-${asset.type}`">
      <div class="item-inner">
        <div class="background">
          <div class="inner-background">
            <div
              v-if="isProgram(asset.type) || asset.type == 'extra'"
              class="thumbnail-icon thumbnail-program"
            >
              <FolderType />
              <div class="inner-image thumbnail-program">
                <img
                  v-if="isProgram(asset.type)"
                  :src="asset.thumb"
                  @contextmenu="handleContextMenu"
                  alt=""
                />
              </div>
            </div>
            <div
              :class="[`thumbnail-media`]"
              v-else-if="
                isImage(asset.type, asset.extension) || (isVideo(asset.type, asset.extension) && asset.thumb)
              "
            >
              <div class="inner-image">
                <img
                  :src="asset.thumb"
                  @click="handleClick(asset_index)"
                  @contextmenu="handleContextMenu"
                  alt=""
                />
              </div>
            </div>

            <IconType v-else-if="asset.extension" :title="asset.extension.toUpperCase()" />

            <div
              v-if="isImage(asset.type, asset.extension) && asset.thumb"
              class="left bottom-30 indication"
            >
              <div :class="`indication--image`"></div>
            </div>
            <div
              v-if="isVideo(asset.type, asset.extension) && asset.thumb"
              class="center indication"
            >
              <div :class="`indication--video`"></div>
            </div>
          </div>
        </div>
        <div class="item-info">
          <div class="item-title">{{ asset.name }}</div>
          <div class="item-title">{{ asset.hsize }}</div>
          <div class="item-subtitle">{{ asset.extension || asset.type | upper }}</div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import IconType from "./IconType";
import FolderType from "./FolderType";

import mixin from "@/mixin";

export default {
  mixins: [mixin],
  components: { IconType, FolderType },
  props: ["asset", "asset_index", "hash", "install"],
  data() {
    return {
      onLoadStyleImage: null,
    };
  },
  filters: {
    upper: function (string) {
      return string.toUpperCase();
    },
  },
  methods: {
    handleClick() {
      this.$emit("handleClick");
    },
    handleContextMenu() {
      this.$emit("handleContextMenu");
    },
  },
  mounted() {
    this.onLoadStyleImage = {
      backgroundImage: "url(" + require("../assets/damier.svg") + ")",
      animation: "unset",
      backgroundSize: "contain",
    };
  },
};
</script>

<style lang="scss">
.item {
  padding: 10px;
  border-radius: 6px;
  background: white;
  border-radius: 8px;
}
.selected {
  background: rgb(240, 240, 240);
}
.selected .item-title {
  background-color: rgb(0, 162, 255);
  color: white;
}

.item-inner {
  height: 100%;
  widows: 100%;
}

.item-title {
  margin-top: 10px;
  font-size: 13px;
  padding: 0 5px;
  border-radius: 4px;
  color: rgb(65, 65, 65);
  font-weight: 500;
  text-align: center;
  line-height: 17px;
  height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-subtitle {
  text-align: center;
  color: #b7b7b7;
  font-size: 0.8rem;
  font-weight: 500;
}
.indication {
  position: absolute;
}
.indication--video {
  background-image: url(../assets/indication_video.svg);
  height: 40px;
  width: 40px;
}
.indication--image {
  background-image: url(../assets/indication_image.svg);
  height: 20px;
  width: 20px;
}

.inner-background {
  position: relative;
  display: flex;
  justify-content: center;
  height: 130px;
  /* min-width: 180px; */
  border-radius: 4px;
}

.thumbnail-program {
  width: 120px;
  position: relative;
}

.inner-image {
  height: 130px;
}

.thumbnail-program .inner-image {
  height: 60px;
  width: 90px;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(7deg);
  position: absolute;
  z-index: 2;
}
.thumbnail-program .inner-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    #dbdbdb 25%,
    hsl(0, 0%, 72%) 50%,
    #dbdbdb 75%
  );
  background-size: 400% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 4px;
}
.thumbnail-media .inner-image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.background img {
  height: 100%;
  width: 100%;
}

.thumbnail-media {
  height: auto;
  max-height: 130px;
  border-radius: 4px;
  overflow: hidden;
}
.thumbnail-icon {
  height: 120px;
  width: 120px;
  background-position: center;
  background-size: cover;
}
.thumbnail-folder,
.thumbnail-extra {
  background-image: url(../assets/folder.svg);
}
.thumbnail-csv {
  background-image: url(../assets/csv.svg);
}
/* .thumbnail-media {
  background-image: url(../assets/image.svg);
} */
.thumbnail-xls {
  background-image: url(../assets/excel.png);
}
.thumbnail-docx {
  background-image: url(../assets/word.png);
}
</style>