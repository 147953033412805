import * as utils from './utils.js'
import { mapGetters } from "vuex";

const { installToInfos } = utils

const mixin = {
  computed: {
    ...mapGetters(["isError", "errorMessageLogin"]),
    installInfos() {
      return installToInfos?.[this.$route.params.install] ?? this.$router.push({
        name: "404",
      });
    }
  },
  methods: {
    emmitNotificationError: function (message, callback) {
      const notif = this.$buefy.notification.open({
        duration: 5000,
        message: message,
        position: "is-bottom-right",
        type: "is-danger",
        "progress-bar": true,
        hasIcon: true,
      });
      notif.$on("close", () => {
        callback()
      });
    },

  }
}

for (let key in utils) {
  if (typeof utils[key] === 'function') {
    mixin.methods[key] = utils[key]
  }
}
export default mixin