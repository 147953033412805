import router from './router'
import store from './store'
import Vue from "vue";
import App from "./App.vue"

import './styles/app.scss'

import vuetify from './plugins/vuetify'

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
