<template>
  <div v-if="items && headers">
    <v-data-table
      dense
      fixed-header
      height="500"
      :headers="headers"
      :items="items"
      :items-per-page="10"
      :loading="loading"
      class="elevation-1"
    >
    <template v-for="(el, property) in largeProperties" #[`item.${property}`]="{ item }">
      <TooltipLargeContentVue :property="property" :item="item" :key="property" />
    </template>
  </v-data-table>
  </div>
</template>

<script>
import Papa from "papaparse";
import TooltipLargeContentVue from "./TooltipLargeContent.vue";
export default {
  data() {
    return {
      table: [],
      head: null,
      body: null,
      items: [],
      headers: [],
      largeProperties: {},
      loading: false
    };
  },
  components: {TooltipLargeContentVue},
  props: {
    file: String,
  },
  mounted() {
    this.loading = true
    Papa.parse(this.file, {
      download: true,
      complete: (results) => {
        const table = results.data;
        this.head = table[0];
        this.body = table.filter((el, index) => index > 0);

        const headers = table[0].map(el => {
          const item = { text: el.split('_').map(el => el[0].toUpperCase() + el.substring(1).toLowerCase()).join(' '), value: el.toLowerCase() }
          item['width'] = '100px'
          return item
        })
        const items = table.filter(el => el[0] != "").filter((el, index) => index > 0).map(row => {
          const item = {}

          for(const index in headers) {
            if(headers[index]?.value) {
              if(row[index].length > 50) {
                this.largeProperties[headers[index].value] = true
              }
              item[headers[index].value] = row[index]
            }
          }
          return item
        })


        this.headers = headers
        this.items = items
        this.loading = false
      },
      delimeter: ";",
      delimitersToGuess: [';', ',']
    });
  },
};
</script>