<template>
  <header
    ref="headerShare"
    :style="`height: ${$store.state.messageHeight + 200}px;`"
  >
    <div class="header-inner">
      <a :href="preview.configuration.url" target="_blank">
        <div class="company-container">
          <div class="company">
            <img :src="preview.configuration.logo" class="company-logo" />
          </div>
        </div>
      </a>
      <div class="flex">
        <SenderCard :infos="preview" :isFile="isFile" />
      </div>
    </div>
  </header>
</template>

<script>
import SenderCard from "@/components/SenderCard.vue";
export default {
  props: ["preview", "isFile"],
  components: { SenderCard },
};
</script>
<style>
header {
  background: var(--background);
  height: min-content;
  transition: height 200ms;
  margin-bottom: 20px;
}
.body.is_file header {
  height: 150px !important;
}
.body.is_file .header-inner {
  max-height: 154px;
  transition: max-height 200ms;
}
.header-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-height: 4000px;
  padding: 20px 0;
  row-gap: 15px;
  transition: max-height 200ms;
}
.company-logo {
  height: 100px;
  width: 100px;
  object-fit: contain;
  transition: 300ms;
}

.company-name {
  font-size: 20px;
  color: white;
  font-weight: 700;
}
.company-container {
  height: 100px;
}

.body.is_file .company-container {
  position: unset;
}

.body.is_file .company {
  flex-direction: column;
  align-items: center;
}
.company {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  left: 0px;
  top: 0%;
  transform: translateY(0%);
  transition: 200ms;
}
.company a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

@media screen and (max-width: 1024px) {
  .company-container {
    height: 70px;
    width: 100%;
    position: relative;
  }

  .company {
    position: unset;
  }
  .body.is_file .company-container {
    position: unset;
    height: 70px;
  }

  .body.is_file .company {
    transform: translateY(0%);
  }
  .company-logo {
    height: 70px;
    width: 70px;
  }
  .header-inner {
    row-gap: 8px;
    margin: 0px 10px;
  }
  .header-inner .flex {
    gap: 5px;
  }
}
</style>