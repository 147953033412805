export const EXPIRED = 410
export const NOT_FOUND = 404
export const PATH_NOT_EXIST = 'path-not-exist'
export const isProgram = type => ['episode', 'standalone', 'season', 'serie', 'placeholder', 'program'].includes( type )
export const isVideo = ( type, extension ) => ['screener', 'video'].includes( type ) && ['mp4'].includes( extension )
export const isImage = ( type, extension ) => ['picture', 'screenshot', 'default', 'image', 'portrait', 'hero', 'logo', ""].includes( type ) && ['png', 'jpg', 'jpeg', 'gif'].includes( extension )
export const messageFromErrorCode = {
  410: "The share has expired",
  404: "The share does not exist",
  'path-not-exist': "The element does not exist"
}
export const installToInfos = {
  myzen: {
    logo: "/logo-myzen.png",
    visuel: "/visuel-myzen.jpg",
  },
  museum: {
    logo: "/logo-museum.png",
    visuel: "/visuel-museum.png",
  },
  melody: {
    logo: "/logo-melody.png",
    visuel: "/visuel-melody.jpg",
  },
  figaro: {
    logo: "/logo-figaro.png",
    visuel: "/visuel-figaro.jpg",
  }
}
export const checkValidEmail = ( email ) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}