import VueRouter from "vue-router";
import Vue from "vue";
import Cookies from "js-cookie";
import axios from "axios";

Vue.use(VueRouter);

import ViewBrowser from "./views/Browser";
import ViewLogin from "./views/Login";
import View404 from "./views/404";
import store from "./store";
import { EXPIRED } from "@/utils";

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/:install/:hash",
      component: ViewLogin,
      name: "Login",
      meta: { title: "Login", displayname: "Login", transition: 'fade' },
      beforeEnter: (to, from, next) => {
        const pass = Cookies.get("pass");
        if (!pass) {
          next();
        } else {
          router.push({
            name: "Browse",
            params: { hash: to.params.hash, install: to.params.install, path: "home" },
          });
        }
      }
    },
    {
      path: "/:install/:hash/:path*",
      component: ViewBrowser,
      name: "Browse",
      meta: { title: "Browse", transition: 'fade' },
      displayName: "Browse",
      beforeEnter: (to, from, next) => {
        const pass = Cookies.get("pass");
        if (!pass) {
          next({
            name: "Login",
            params: { hash: to.params.hash, install: to.params.install },
          });
        } else {


          const handleErrorShare = err => {
            Cookies.remove("pass");
            store.commit("SET_IS_LOADING_SHARE", false);
            store.dispatch("setErrorCode", err.response.status);
            if (err.response.status == EXPIRED) {
              next({
                name: "Login",
                params: { hash: to.params.hash, install: to.params.install },
              });
            }
          }


          // On appelle le share sans les assets
          store.commit("SET_IS_LOADING_SHARE", true);
          const urlPreview = `https://${to.params.install}.secom.io/data/shares/${to.params.hash}/?pass=${pass}&preview=1`;
          axios.get(urlPreview).then((data) => {
            if (store.state.share == null) {
              store.commit("SET_IS_LOADING_SHARE", false);
              store.commit("SET_SHARE", data.data);
              next();
            }
          }).catch(handleErrorShare);

          // On appelle le share avec les assets
          const url = `https://${to.params.install}.secom.io/data/shares/${to.params.hash}/?pass=${pass}`;
          axios.get(url).then((data) => {
            store.commit("SET_IS_LOADING_SHARE", false);
            store.commit("SET_SHARE", data.data);
            next();
          }).catch(handleErrorShare);
        }
      },
    },
    {
      path: "*", component: View404, name: "404"
    },
  ],
});

export default router;
